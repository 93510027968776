<template>
  <baseTemplate>
    <div class="about-us-box">
      <Logo />
      <div class="v-box">V{{ $andirodData.Ubwafwilisho_Application }}</div>
      <div class="about-us-content" v-html="$t('settings.aboutUsRemark')" />
    </div>
  </baseTemplate>
</template>

<script>
import Logo from '@/components/Logo.vue'
import { projectName } from '@/settings'
export default {
  components: { Logo },
  data() {
    return {
      projectName: projectName
    }
  }
}
</script>
<style lang="scss" scoped>
.about-us-box{
 padding-top: 118px;
}
.about-us-content{
  padding-top: 64px;
}
:deep(.logo-box){
  width: 192px;
  height: 192px;
}
:deep(.remark){
  font-size: 36px;
  font-weight: 500;
  color: #292A4D;
  line-height: 48px;
  margin-top: 24px;
}
.v-box{
  margin-top: 4px;
  height: 48px;
  font-size: 30px;
  font-weight: 400;
  color: #292A4D;
  line-height: 48px;
  text-align: center;
}
</style>
<style>
.about-us-content p{
  font-size: 30px;
  font-weight: 400;
  color: #292A4D;
  line-height: 44px;
}
</style>
